"use client";

import { Button } from "@/components/ui/button";
import { useIsMobile } from "@/hooks/use-mobile";
import { Link } from "@/i18n/routing";
import { config } from "@/lib/config";
import {
  SignedIn,
  SignedOut,
  SignInButton,
  UserButton,
  useUser,
} from "@clerk/nextjs";
import { ArrowRight, Menu, PhoneIcon, X } from "lucide-react";
import { useTranslations } from "next-intl";
import Image from "next/image";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import LanguageSwitcher from "./LanguageSwitcher";

function Nav() {
  const t = useTranslations();
  const [scrolled, setScrolled] = useState(false);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const pathname = usePathname();
  const isMobile = useIsMobile();

  const isMainPage = pathname === "/" || pathname === "/en";

  const navItems = [
    {
      name: t("navigation.home"),
      href: "/",
    },
    {
      name: t("navigation.cars"),
      href: "/rent-a-car-bucuresti",
    },
    {
      name: t("navigation.privateJet"),
      href: "/private-jet",
    },
    {
      name: t("navigation.airportTransfer"),
      href: "/transfer-aeroport",
    },
    {
      name: t("navigation.vans"),
      href: "/inchiriere-dube",
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleNavItemClick = () => {
    setMobileNavOpen(false);
  };

  return (
    <>
      <nav
        className={`fixed top-[10px] left-1/2 -translate-x-1/2   z-50 ${
          scrolled || isMobile || !isMainPage ? "container" : "w-full"
        }`}
      >
        {/* black header  */}
        <div className="bg-black text-white flex items-center justify-center text-center rounded-[10px] mb-1 ">
          <span className="hidden lg:block mr-1">
            {t("navigation.newContact")}
          </span>
          <span className="lg:hidden mr-1">{t("navigation.callTo")}</span>
          <span className="text-brand-yellow">0750 481 850</span>
        </div>

        {scrolled || isMobile || !isMainPage ? (
          <ScrolledNavBar
            navItems={navItems}
            setMobileNavOpen={setMobileNavOpen}
          />
        ) : (
          <NonScrolledNavBar
            navItems={navItems}
            setMobileNavOpen={setMobileNavOpen}
          />
        )}
      </nav>
      {/* Mobile Navigation */}
      <div
        className={`fixed left-0 w-screen h-screen bg-[#E9E4DF] z-[1000] flex flex-col p-4 px-7 transform transition-transform duration-300 ${
          isMobileNavOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <X
          className="w-6 h-6 absolute top-5 right-10 cursor-pointer"
          onClick={() => setMobileNavOpen(false)}
        />
        <div className="flex justify-between items-center mt-10 mb-4">
          <Image
            src="https://eleircbucuresti.b-cdn.net/brand_identity/Elei-logo-black%2Bwhite.webp"
            alt="logo"
            width={105}
            height={56}
            onClick={handleNavItemClick}
          />
        </div>
        <ul className="flex flex-col items-start gap-4">
          {navItems.map((item) => (
            <li key={item.name} className="relative group font-semibold ">
              <Link
                href={item.href}
                className="text-black/80 group-hover:text-black transition-colors duration-300"
                onClick={handleNavItemClick}
              >
                {item.name}
              </Link>
              <div
                className={`absolute left-0 h-1 rounded-full -bottom-1 bg-black group-hover:w-full transition-all duration-300 ${
                  pathname.includes(item.href) ? "w-full" : "w-0"
                }`}
              />
            </li>
          ))}
        </ul>

        <div className="flex flex-col items-start mt-4">
          <LanguageSwitcher />
          <Button
            asChild
            variant="yellow"
            className="mt-4"
            onClick={handleNavItemClick}
          >
            <Link href="tel:+40750481850">
              <PhoneIcon className="w-[20px] h-[20px]" fill="black" />
              {t("navigation.callNow")}
            </Link>
          </Button>
        </div>
      </div>
    </>
  );
}

export default Nav;

const ScrolledNavBar = ({
  navItems,
  setMobileNavOpen,
}: {
  navItems: { name: string; href: string }[];
  setMobileNavOpen: (open: boolean) => void;
}) => {
  const pathname = usePathname();
  const t = useTranslations();
  const { user } = useUser();

  return (
    <div
      className={`rounded-[20px] flex justify-between items-center px-[30px] py-[20px] shadow-[0_8px_24px_rgba(149,157,165,0.2)]  border border-white/20 transition-colors duration-200 bg-white
            
          `}
    >
      <Link href="/" className="block">
        <div className="relative w-[105px] h-[56px]">
          <Image
            src="https://eleircbucuresti.b-cdn.net/brand_identity/Elei-logo-black%2Bwhite.webp"
            alt="logo"
            fill
            className="object-contain"
          />
        </div>
      </Link>
      <ul className="gap-[35px] lg:text-[16px] hidden lg:flex">
        {navItems.map((item) => (
          <li key={item.name} className="relative group ">
            <Link
              href={item.href}
              className="text-black/80 group-hover:text-black transition-colors duration-300"
            >
              {item.name}
            </Link>
            <div
              className={`absolute left-0 h-1 rounded-full -bottom-1 bg-black group-hover:w-full transition-all duration-300 ${
                pathname == item.href ? "w-full" : "w-0"
              }`}
            />
          </li>
        ))}
      </ul>

      <div className="flex items-center gap-4">
        <SignedOut>
          <SignInButton mode="modal" />
        </SignedOut>
        <SignedIn>
          <UserButton />
        </SignedIn>

        <LanguageSwitcher />
        {user?.organizationMemberships.some(
          (membership) => membership.role === "org:admin"
        ) && (
          <Button asChild variant="yellow">
            <Link href="/admin">{t("navigation.admin")}</Link>
          </Button>
        )}

        {/* hamburger menu */}
        <Menu
          className="lg:hidden w-6 h-6 cursor-pointer"
          onClick={() => setMobileNavOpen(true)}
        />
      </div>
    </div>
  );
};

const NonScrolledNavBar = ({
  navItems,
  setMobileNavOpen,
}: {
  navItems: { name: string; href: string }[];
  setMobileNavOpen: (open: boolean) => void;
}) => {
  const pathname = usePathname();
  const t = useTranslations();
  const { user } = useUser();

  return (
    <div className="w-full flex justify-between items-center relative">
      <Link href="/" className="block bg-brand-yellow py-3 px-10">
        <div className="relative w-[105px] h-[56px]">
          <Image
            src="https://eleircbucuresti.b-cdn.net/brand_identity/Elei-logo-black%2Bwhite.webp"
            alt="logo"
            fill
            className="object-contain"
          />
        </div>
      </Link>
      <div
        className={`rounded-[20px]  gap-10 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex justify-between items-center px-[30px] py-[10px] shadow-[0_8px_24px_rgba(149,157,165,0.2)]  border border-white/20 transition-colors duration-200 bg-white
            
          `}
      >
        <ul className="gap-[35px] lg:text-sm  items-center hidden lg:flex">
          {navItems.map((item) => (
            <li key={item.name} className="relative text-center  group ">
              <Link
                href={item.href}
                className="text-black/80 group-hover:text-black transition-colors duration-300"
              >
                {item.name}
              </Link>
              <div
                className={`absolute left-0 h-1 rounded-full -bottom-1 bg-black group-hover:w-full transition-all duration-300 ${
                  pathname == item.href ? "w-full" : "w-0"
                }`}
              />
            </li>
          ))}
        </ul>

        <div className="flex items-center font-bold gap-4">
          <SignedOut>
            <SignInButton mode="modal" />
          </SignedOut>
          <SignedIn>
            <UserButton />
          </SignedIn>

          <LanguageSwitcher />
          {user?.organizationMemberships.some(
            (membership) => membership.role === "org:admin"
          ) && (
            <Button asChild variant="yellow">
              <Link href="/admin">{t("navigation.admin")}</Link>
            </Button>
          )}

          {/* hamburger menu */}
          <Menu
            className="lg:hidden w-6 h-6 cursor-pointer"
            onClick={() => setMobileNavOpen(true)}
          />
        </div>
      </div>
      <div className="flex items-center gap-4 pr-10">
        <Button asChild variant="yellow" className="">
          <Link href="/rent-a-car-bucuresti">
            <ArrowRight className="w-[20px] h-[20px]" fill="black" />
            See cars
          </Link>
        </Button>
        <Button
          onClick={() => window.open(`tel:${config.eleiPhoneNumber}`, "_blank")}
          variant="default"
          className=""
        >
          <PhoneIcon className="w-[20px] h-[20px]" fill="black" />
          Call now
        </Button>
      </div>
    </div>
  );
};
