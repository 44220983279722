"use client";

import { Button } from "@/components/ui/button";
import { Protect } from "@clerk/nextjs";
import { AbstractIntlMessages, NextIntlClientProvider } from "next-intl";
import Link from "next/link";
import { usePathname } from "next/navigation";
import AdminLayout from "./(admin)/admin-layout";
import AppLayout from "./app-layout";

export default function DynamicLayout({
  children,
  locale,
  messages,
}: {
  children: React.ReactNode;
  locale: string;
  messages: AbstractIntlMessages;
}) {
  const pathname = usePathname();
  const isAppLayout = !pathname.includes("/admin");

  return (
    <>
      {isAppLayout ? (
        <NextIntlClientProvider
          locale={locale}
          messages={messages}
          onError={(error) => {
            if (error.code === "MISSING_MESSAGE") return;
            console.error(error);
          }}
        >
          <AppLayout>{children}</AppLayout>
        </NextIntlClientProvider>
      ) : (
        <Protect
          role="org:admin"
          fallback={
            <div className="flex flex-col items-center justify-center w-screen h-screen">
              <p className="text-2xl font-bold">
                Nu ai acces la aceasta pagina
              </p>
              <Button variant="yellow-gradient" asChild>
                <Link href="/">
                  <div>Inapoi la pagina principala</div>
                </Link>
              </Button>
            </div>
          }
        >
          <AdminLayout>{children}</AdminLayout>
        </Protect>
      )}
    </>
  );
}
