import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/[locale]/dynamic-layout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SyncActiveOrganization"] */ "/vercel/path0/app/[locale]/SyncActiveOrganization.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientClerkProvider"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["AuthenticateWithRedirectCallback","ClerkLoaded","ClerkLoading","RedirectToCreateOrganization","RedirectToOrganizationProfile","RedirectToSignIn","RedirectToSignUp","RedirectToUserProfile"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["useAuth","useClerk","useEmailLink","useOrganization","useOrganizationList","useReverification","useSession","useSessionList","useSignIn","useSignUp","useUser"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
;
import(/* webpackMode: "eager", webpackExports: ["PromisifiedAuthProvider"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/PromisifiedAuthProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["CreateOrganization","GoogleOneTap","OrganizationList","OrganizationProfile","OrganizationSwitcher","SignIn","SignInButton","SignInWithMetamaskButton","SignOutButton","SignUp","SignUpButton","UserButton","UserProfile","Waitlist"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\".././fonts/GeistVF.woff\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"geistSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\".././fonts/GeistMonoVF.woff\",\"variable\":\"--font-geist-mono\",\"weight\":\"100 900\"}],\"variableName\":\"geistMono\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"variable\":\"--font-poppins\"}],\"variableName\":\"poppins\"}");
