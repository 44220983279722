"use client";
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@/components/ui/sidebar";
import {
  BringToFront,
  Calendar,
  Car,
  DollarSign,
  FileIcon,
  LayoutDashboard,
  Paperclip,
  PlaneIcon,
  Target,
  UserRoundPen,
  Users,
} from "lucide-react";

const items = [
  {
    title: "Dashboard",
    url: "/",
    icon: LayoutDashboard,
  },
  {
    title: "Cheltuieli",
    url: "/cheltuieli",
    icon: DollarSign,
  },
  {
    title: "Parteneri",
    url: "/parteneri",
    icon: Users,
  },
  {
    title: "Masini",
    url: "/masini",
    icon: Car,
  },
  {
    title: "Branduri auto",
    url: "/branduri-auto",
    icon: Target,
  },
  {
    title: "Inchirieri",
    url: "/inchirieri",
    icon: BringToFront,
  },
  {
    title: "Contracte",
    url: "/contracte",
    icon: UserRoundPen,
  },
  {
    title: "Transport Aeroport",
    url: "/transport-aeroport",
    icon: PlaneIcon,
  },
  {
    title: "Procese",
    url: "/procese",
    icon: FileIcon,
  },
  {
    title: "Bloguri",
    url: "/bloguri",
    icon: Paperclip,
  },
  {
    title: "Calendar",
    url: "/calendar",
    icon: Calendar,
  },
];

export function AdminSidebar() {
  return (
    <Sidebar variant="inset">
      <SidebarHeader>
        <h2 className="text-2xl font-bold">ELEI CMS</h2>
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          {/* <SidebarGroupLabel>Application</SidebarGroupLabel> */}
          <SidebarGroupContent>
            <SidebarMenu>
              {items.map((item) => (
                <SidebarMenuItem key={item.title}>
                  <SidebarMenuButton asChild>
                    <a href={`/admin${item.url}`}>
                      {item.icon && <item.icon />}
                      <span>{item.title}</span>
                    </a>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              ))}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
    </Sidebar>
  );
}
