"use client";

import Script from "next/script";

export default function TidioChat() {
  return (
    <Script
      src="https://code.tidio.co/qexigbhdzkv5m43wbnz765pbaodpyg0c.js"
      strategy="lazyOnload"
    />
  );
}
