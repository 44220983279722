import { SidebarInset, SidebarProvider } from "@/components/ui/sidebar";
import Header from "./Header";
import { AdminSidebar } from "./AdminSidebar";

export default function AdminLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <SidebarProvider>
      <AdminSidebar />
      <SidebarInset className="overflow-hidden">
        <Header />
        {children}
      </SidebarInset>
    </SidebarProvider>
  );
}
