import { SignedIn, UserButton } from "@clerk/nextjs";
import { Bell } from "lucide-react";
import Link from "next/link";

function Header() {
  return (
    <header className="flex p-2 bg-background shrink-0 items-center gap-2 justify-end border-b px-4">
      {/* <CustomTrigger /> */}

      <div className="flex items-center gap-5">
        <SignedIn>
          <UserButton />
        </SignedIn>

        <Link href="/admin/notificari">
          <Bell className="w-6 h-6" />
        </Link>
      </div>
    </header>

  );
}

export default Header;
