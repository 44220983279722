"use client";
import { usePathname, useRouter } from "@/i18n/routing";
import { useLocale } from "next-intl";
import Image from "next/image";

function LanguageSwitcher() {
  const router = useRouter();
  const pathname = usePathname();
  const locale = useLocale();

  const locales = ["ro", "en"];

  const switchLanguage = (locale: string) => {
    // Switch to the opposite locale
    const newLocale = locale === "ro" ? "en" : "ro";
    console.log("Current locale:", locale);
    console.log("Switching to:", newLocale);
    console.log("Current pathname:", pathname);

    try {
      // For homepage, use root path
      if (pathname === "/") {
        router.push("/", { locale: newLocale });
        return;
      }

      // For other pages
      const newPath = pathname;
      console.log("New path will be:", newPath);
      router.push(newPath, { locale: newLocale });
    } catch (error) {
      console.error("Error switching language:", error);
    }
  };

  return (
    <div className="relative flex items-center gap-2 justify-center">
      {locales.map((showLocale) => (
        <button
          key={showLocale}
          onClick={() => switchLanguage(showLocale)}
          className={`w-10 h-7 rounded-md   relative ${
            locale !== showLocale ? "ring-4 ring-brand-yellow" : ""
          }`}
        >
          <Image
            src={showLocale === "en" ? "/ro-flag.svg" : "/en-flag.svg"}
            alt="Romanian flag"
            fill
            className="object-cover"
          />
        </button>
      ))}
    </div>
  );
}

export default LanguageSwitcher;
