import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatDate(date: Date) {
  return new Intl.DateTimeFormat("ro-RO", {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(date);
}

export const formatUrlText = (title: string) => {
  // lease dont add - at the end of the string
  return title.replace(/\s+/g, "-").toLowerCase().replace(/-$/, "");
};
export const formatUrlTextBlog = (title: string) => {
  return title.replace(/\s+/g, "_");
};


// Helper function to generate a unique filename
export const generateUniqueFilename = (originalFilename: string): string => {
  const extension = originalFilename.split(".").pop();
  const timestamp = Date.now();
  const random = Math.random().toString(36).substring(2, 15);
  return `${timestamp}-${random}.${extension}`;
};

