import Footer from "@/components/sections/footer";
import TidioChat from "@/components/TidioChat";
import React from "react";
import Nav from "./nav";

function AppLayout({ children }: { children: React.ReactNode }) {
  return (
    <div>
      <Nav />
      {children}
      <Footer />
      <TidioChat />
    </div>
  );
}

export default AppLayout;
