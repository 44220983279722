"use client";
import { Facebook, Instagram, Map, Phone } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { Button } from "../ui/button";
import { useTranslations } from "next-intl";

function Footer() {
  const t = useTranslations("footer");
  return (
    <footer className="bg-[url('/footer-bg.png')] py-[65px] bg-cover lg:bg-center bg-no-repeat">
      <div className="container">
        <div className="mb-[70px] text-center">
          <h2 className="mb-[20px] text-[24px] lg:text-[48px] leading-[48px] lg:leading-[72px] text-white font-bold">
            {t("title1")}
            <span className="gradient-text">{t("title2")}</span>
          </h2>
          <p className="mb-[30px] text-white/60">{t("subtitle")}</p>

          <Button asChild variant="yellow-gradient" className="shadow-none p-7">
            <Link href="tel:+40750481850">{t("button")}</Link>
          </Button>
        </div>

        {/* footer list */}
        <ul className="flex flex-col lg:flex-row justify-between  gap-5">
          {/* servicii  complete*/}
          <li className="flex-1">
            <Image
              src="https://elei.b-cdn.net/WhiteLogo.webp"
              alt="logo"
              width={105}
              height={100}
            />
            <p className="text-white/60 mt-4">{t("miniDescription")}</p>
          </li>
          {/* linkuri utile */}
          <li className="flex-1">
            <h5 className="text-white text-[16px] font-medium mb-[28px]">
              {t("links.usefulLinks")}
            </h5>

            <ul className="flex flex-col gap-2 text-white/60 text-[16px]">
              <li>
                <Link className="hover:text-brand-yellow" href="/">
                  {t("links.home")}
                </Link>
              </li>
              <li>
                <Link
                  className="hover:text-brand-yellow"
                  href="/transfer-aeroport"
                >
                  {t("links.airportTransfer")}
                </Link>
              </li>
              <li>
                <Link
                  className="hover:text-brand-yellow"
                  href="/sofer-personal"
                >
                  {t("links.personalDriver")}
                </Link>
              </li>
              <li>
                <Link
                  className="hover:text-brand-yellow"
                  href="/rent-a-car-bucuresti"
                >
                  {t("links.allCars")}
                </Link>
              </li>
              <li>
                <Link
                  className="hover:text-brand-yellow"
                  href="/inchiriere-dube-bucuresti"
                >
                  {t("links.rentVans")}
                </Link>
              </li>
              <li>
                <Link
                  className="hover:text-brand-yellow"
                  href="/intrebari-frecvente-inchiriere-masini-bucuresti"
                >
                  {t("links.questions")}
                </Link>
              </li>
            </ul>
          </li>
          {/* protectia datelor */}
          <li className="flex-1">
            <h5 className="text-white text-[16px] font-medium mb-[28px]">
              {t("links.terms")}
            </h5>

            <ul className="flex flex-col gap-2 text-white/60 text-[16px]">
              <li>
                <Link
                  className="hover:text-brand-yellow"
                  href="/termeni-si-conditii"
                >
                  {t("links.terms")}
                </Link>
              </li>
            </ul>
          </li>
          {/* pastreaza legatura */}
          <li className="flex-1">
            <h5 className="text-white text-[16px] font-medium mb-[28px]">
              {t("links.keepInTouch")}
            </h5>
            <ul className="flex flex-col  text-white text-[16px]">
              <Link href="tel:+40750481850">
                <li className="flex items-start   gap-2">
                  <Phone className="size-4  mt-2" fill="#fff" />
                  <p className="text-white ">0750 481 850</p>
                </li>
              </Link>
              <li className="flex items-start gap-2">
                <Map className="size-6  mt-1" fill="#fff" />
                <p className="text-white">
                  {" "}
                  Bulevardul Pipera Nr.2 C1, et7, birou 17, Ilfov (Voluntari)
                </p>
              </li>

              <ul className="flex gap-2">
                <li className="flex items-center justify-center rounded-full   border-white p-2">
                  <Link href="https://www.facebook.com/rentcarelei/">
                    <Facebook className="size-6" fill="#fff" />
                  </Link>
                </li>
                <li className="flex items-center justify-center rounded-full   border-white p-2">
                  <Link href="https://www.instagram.com/rentelei/">
                    <Instagram className="size-6" />
                  </Link>
                </li>
              </ul>
            </ul>
          </li>
        </ul>
      </div>
    </footer>
  );
}
export default Footer;
